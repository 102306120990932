<template>
    <v-row>
        <v-col cols="12">
            <p class="text-h4 text-center font-weight-bold">Recipiente {{ container.me9idrecip }}</p>
        </v-col>
        <v-col cols="12" sm="6">
            <v-img src="https://http2.mlstatic.com/D_NQ_NP_937971-MLB31205883552_062019-O.jpg" alt="cilindro" height="100%"/>
        </v-col>
        <v-col cols="12" sm="6">
            <v-list class="detailsRecip mb-6">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="text-left font-weight-bold subtitle-1">Descrição</v-list-item-title>
                        <v-list-item-subtitle class="text-left">{{ container.me9desc }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="text-left font-weight-bold subtitle-1">Código do cilindro</v-list-item-title>
                        <v-list-item-subtitle class="text-left">{{ container.me9recip }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="text-left font-weight-bold subtitle-1">Fabricante</v-list-item-title>
                        <v-list-item-subtitle class="text-left">{{ container.in6nome }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="text-left font-weight-bold subtitle-1">Código do fabricante (Chassi)</v-list-item-title>
                        <v-list-item-subtitle class="text-left">{{ container.me9codfab }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="text-left font-weight-bold subtitle-1">Data de fabricação</v-list-item-title>
                        <v-list-item-subtitle class="text-left">{{ container.me9dtfab }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="text-left font-weight-bold subtitle-1">Data de teste</v-list-item-title>
                        <v-list-item-subtitle class="text-left">{{ container.me9dttes }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="text-left font-weight-bold subtitle-1">Capacidade em litros</v-list-item-title>
                        <v-list-item-subtitle class="text-left">{{ container.me9litros }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="text-left font-weight-bold subtitle-1">Capacidade em quilos</v-list-item-title>
                        <v-list-item-subtitle class="text-left">{{ container.me9kg }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="text-left font-weight-bold subtitle-1">Capacidade em metros cúbicos</v-list-item-title>
                        <v-list-item-subtitle class="text-left">{{ container.me9m3 }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="text-left font-weight-bold subtitle-1">Especificações</v-list-item-title>
                        <v-list-item-subtitle>
                            <v-textarea
                                v-model="container.me9especif"
                                readonly
                                solo
                                flat
                                rows="3"
                            ></v-textarea>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-col>        
    </v-row>
</template>

<script>
export default {
    props:{
        container:{
            required: true,
            type: Object
        }
    }
}
</script>

<style scoped>
.detailsRecip{
    border: black 0.5px solid;
    border-radius: 20px;
}
</style>