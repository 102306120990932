<template>
    <v-row>
        <v-col cols="12">
            <v-list three-line>
                <v-subheader>Recipientes encontrados</v-subheader>
                <template v-for="(container, index) in state.containers">
                    <v-divider
                        v-if="!index"
                        :key="index"
                        inset
                    ></v-divider>

                    <v-list-item :key="`container${index}`" @click="open(container)">
                        <v-list-item-avatar>
                            <v-icon
                                class="primary"
                                dark
                            >
                                mdi-gas-cylinder
                            </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title class="font-weight-bold text-left" style="white-space: normal;">Recipiente {{ `${container.me9idrecip} - ${container.me9desc}`  }}</v-list-item-title>
                            <v-list-item-subtitle class="text-left"><b>Chassi:</b> {{ `${container.me9codfab} - ${container.in6nome}` }}</v-list-item-subtitle>
                            <v-list-item-subtitle class="text-left"><b>Código da empresa:</b> {{ `${container.me9codcli}` }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-col>
    </v-row>
</template>

<script>
export default {
    computed:{
        state:{
            get(){
                return this.$store.state
            }
        }
    },
    methods:{
        open(container){
            this.$store.dispatch("findContainer", { search: container.me9idrecip, lxrcodcli: container.me9codcli})
        }
    }
};
</script>

<style>
</style>