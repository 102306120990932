<template>
    <v-main>
        <v-container>
            <v-row v-if="state.loading">
                <v-col cols="12" v-if="state.loading">
                    <v-skeleton-loader
                        v-bind="attrs"
                        height="100%"
                        width="100%"
                        type="article, list-item-three-line, products"
                        :types="{products: 'card@4'}"
                        elevation="0"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
            <v-row v-else-if="state.error || !state.containers || state.containers.length == 0">
                <v-col cols="12">
                    <p class="text-h4 text-center">Recipiente não encontrado 🙁</p>
                </v-col>
            </v-row>
            <template v-else>
                <ContainerList v-if="state.containers && state.containers.length > 1"/>
                <Container v-else :container="state.containers[0]"/>
            </template>
        </v-container>
    </v-main>
</template>

<script>
import ContainerList from "@/components/ContainerList.vue"
import Container from "@/components/Container.vue"

export default {
    data: () => ({
        attrs: {
            class: 'mb-6',
            tile: true,
            elevation: 2,
        },
    }),
    computed:{
        state:{
            get(){
                return this.$store.state
            }
        }
    },
    components:{
        ContainerList,
        Container,
    }
}
</script>

<style>

</style>