import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import(/* webpackMode: "eager" */ /* webpackPrefetch: true */ /* webpackChunkName: "home" */ "@/components/Home.vue");

Vue.use(VueRouter)

const routes = [
	{
		path: '*',
		redirect: '/cilindro/0'
	},
	{
		path: '/',
		redirect: '/cilindro/0'
	},
	{
		path: '/cilindro/:me9idrecip',
		component: Home,
        props: true
    }
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		return { x: 0, y: 0 }
	}
})

export default router