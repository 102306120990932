<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
};
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
#app >>> .v-main {
    min-height: 80% !important;
}
</style>
