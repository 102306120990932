import Vue from 'vue'
import store from './store'
import router from './router/router'
import vuetify from '@/plugins/vuetify'
import App from './App.vue'
import VueMq from 'vue-mq'

import Axios from './plugins/axios'

Vue.prototype.$axios = Axios();

Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    sm: 450,
    md: 1000,
    lg: Infinity,
  },
  defaultBreakpoint: 'sm' // customize this for SSR
})

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
