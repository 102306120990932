<template>
  <v-app-bar flat height="81px" color="#FDFDFD">
      <a href="https://magnadata.com.br/">
            <img
                src="https://magnadata.com.br/assets/site/images/magna-data/magna-data-logo.png"
                alt="Magnadata"
                style="max-height: 65px;"
            />
        </a>
        <v-spacer></v-spacer>

        <v-text-field
            v-model="search"
            placeholder="Pesquise código ou chassi"
            outlined
            dense
            hide-details
            color="primary"
            class="pr-2"
            @keydown="keydown"
            :class="$mq != 'lg' ? 'caption' : 'mr-12'"
            :style="$mq != 'lg' ? 'max-width: 300px !important' : 'max-width: 350px !important'"
        >
            <template v-slot:append>
                <v-icon color="primary" @click="find">mdi-magnify</v-icon>
            </template>
        </v-text-field>
  </v-app-bar>
</template>

<script>
export default {
    data: () => ({
        search: ""
    }),
    methods:{
        keydown(event){
            if (event.keyCode === 13 &&
                !event.shiftKey &&
                this.search)
            {
                this.find()
                event.preventDefault();
            }
        },
        find(){
            this.$router.push(`/cilindro/${this.search}`)
            this.$store.dispatch("findContainer", { search: this.search, lxrcodcli: null})
            this.search = ""
        }
    }
};
</script>

<style>
</style>