<template>
    <v-app id="inspire">
        <AppBar />
        <AppContent />
        <AppFooter />
    </v-app>
</template>

<script>
import AppBar from "@/components/AppBar.vue"
import AppContent from "@/components/AppContent.vue"
import AppFooter from "@/components/AppFooter.vue"

export default {
    components:{
        AppBar,
        AppContent,
        AppFooter,
    },
    beforeRouteUpdate(to, from, next) {
        this.$store.dispatch("findContainer", { search: this.$route.params.me9idrecip, lxrcodcli: this.$route.query.lxrcodcli })
        next()
    },
    mounted(){
        this.$store.dispatch("findContainer", { search: this.$route.params.me9idrecip, lxrcodcli: this.$route.query.lxrcodcli })
    }
};
</script>

<style>
</style>