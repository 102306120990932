<template>
    <v-footer padless>
        <v-card flat tile width="100%">
                <v-row>
                    <v-col cols="12" class="d-flex justify-center">
                        <v-text-field
                            v-model="search"
                            placeholder="Pesquise código ou chassi"
                            outlined
                            dense
                            hide-details
                            color="primary"
                            class="pr-2 pb-6"
                            @keydown="keydown"
                            :class="$mq != 'lg' ? 'caption' : ''"
                            :style="$mq != 'lg' ? 'max-width: 300px !important' : 'max-width: 350px !important'"
                        >
                            <template v-slot:append>
                                <v-icon color="primary" @click="find">mdi-magnify</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <v-card flat tile class="text-center">
                            <v-card-text style="background-color: rgba(0,0,0,.03);">
                                <p class="text-center">Entre em contato e siga-nos nas redes sociais.</p>
                                <v-btn v-for="icon in icons" :key="icon.icon" color="primary" class="mx-4 white--text" icon @click="openSocialMedia(icon.url)">
                                    <v-icon size="24px">
                                        {{ icon.icon }}
                                    </v-icon>
                                </v-btn>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text class="pt-2">
                                <v-row dense>
                                    <v-col cols="12">
                                        <a  href="https://magnadata.com.br/">
                                            <img style="max-height: 65px;"
                                                src="https://magnadata.com.br/assets/site/images/magna-data/magna-data-logo.png"
                                                alt="Magnadata">
                                        </a>
                                    </v-col>
                                    <v-col cols="12">
                                        <span class="text-h6">Construímos soluções tecnológicas para o seu negócio.</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-text style="background-color: rgba(0,0,0,.03);" class="overline py-1">
                                {{ (new Date()).getFullYear() }} MagnaData. Todos os direitos reservados.
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
        </v-card>
    </v-footer>
</template>

<script>
export default {
    data: () => ({
        search: "",
        icons:[
            { icon: "mdi-facebook", url: "https://www.facebook.com/magnaDataSistemas"},
            { icon: "mdi-instagram", url: "https://www.instagram.com/magnadatasistemas/"},
            { icon: "mdi-whatsapp", url: "https://api.whatsapp.com/send?phone=5554996734800&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20da%20empresa."},
            { icon: "mdi-youtube", url: "https://www.youtube.com/channel/UC4rDJm0SrjIXBXVAV3YVs_Q"},
            { icon: "mdi-linkedin", url: "https://www.linkedin.com/company/magnadata/"},
        ]
    }),
    methods:{
        openSocialMedia(link) {
            window.open(link)
        },
        keydown(event){
            if (event.keyCode === 13 &&
                !event.shiftKey &&
                this.search)
            {
                this.find()
                event.preventDefault();
            }
        },
        find(){
            this.$router.push(`/cilindro/${this.search}`)
            this.$store.dispatch("findContainer", { search: this.search, lxrcodcli: null})
            this.search = ""
        }
    }
};
</script>

<style>
</style>