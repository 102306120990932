import Vue from 'vue'
import Vuex from 'vuex'
import { Utils } from "@/plugins/utils";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        containers: [],
        loading: false,
        error: false
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        async findContainer(store, { search, lxrcodcli }) {
            store.state.loading = false
            await this._vm.$axios.get(`/Cilindro/${search}?lxrcodcli=${lxrcodcli}`)
                .then(response => {
                    store.state.containers = response.data;
                })
                .catch((error) => {
                    store.state.error = true
                });
            store.state.loading = false
        }
    }
})